import {
  ref,
  readonly,
  computed,
  useContext,
  InjectionKey,
  provide,
  inject,
} from '@nuxtjs/composition-api'
import { useLoading } from './ui/useLoading'
import { Suggestions } from '~/types/sugesstion'

export const useSearchSuggestion = () => {
  const {
    app: { $api },
  } = useContext()

  const active = ref(false)
  const keyword = ref('')
  const suggestions = ref<Suggestions | null>(null)
  const loading = useLoading()

  const trimedKeyword = computed(() => keyword.value.trim())

  const showSearchPanel = () => {
    if (active.value === true) return
    active.value = true
  }

  const hideSearchPanel = () => {
    if (active.value === false) return
    active.value = false
  }

  const clearKeyword = () => {
    keyword.value = ''
  }

  const suggest = () => {
    return loading.scope(async () => {
      if (trimedKeyword.value) {
        suggestions.value = await $api.productMeili.suggest({
          term: trimedKeyword.value,
        })
      } else {
        suggestions.value = null
      }
    })
  }

  return {
    active: readonly(active),
    keyword,
    trimedKeyword,
    loading: loading.value,
    suggestions: readonly(suggestions),

    showSearchPanel,
    hideSearchPanel,
    clearKeyword,
    suggest,
  }
}

// provide searchSuggestions
const SearchSuggestionProviderKey: InjectionKey<
  ReturnType<typeof useSearchSuggestion>
> = Symbol('Provider:SearchSuggestion')

export const provideSearchSuggestion = () => {
  const result = useSearchSuggestion()
  provide(SearchSuggestionProviderKey, result)
}

// inject searchSuggestions
export const injectSearchSuggestion = () => {
  const result = inject(SearchSuggestionProviderKey)
  if (!result) {
    throw new Error('search suggestions provider not set')
  }
  return result
}
